<template>
  <div id="darkSlot">
    <slot></slot>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
  #darkSlot {
    font-family: 'Poppins',sans-serif;
    h1,h2, h3 {
      color: #FCE181
    }
    height: 100%;
    position: relative;
    // padding: 20px;
    color: #fff;
    border-radius: 10px;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    // background color
    background-color: rgba(0, 0, 0, 0.199);
  }
</style>
