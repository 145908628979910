<template>
  <div id="">
    <div>
      <div id="main-exp">
        <h3 class="mb-3">Compare Widget</h3>
        <!-- one line form with two input field and one button -->
        <form @submit.prevent="compareWords" class="d-flex flex-column">
          <div class="mt-1 d-flex">
            <input
              v-model="word1"
              type="text"
              class="form-control cs-input m-1"
              placeholder="Enter first word"
            />
            <input
              v-model="word2"
              type="text"
              class="form-control m-1"
              placeholder="Enter second word"
            />
          </div>
          <div class="m-1">
            <button type="submit" class="ed-btn__blim ed-btn__sm mb-2">
              Compare
            </button>
          </div>
        </form>
        <!-- end of one line form -->
        <!-- A bootstrap table with compare structure -->
        <div class="table-responsive">
          <table
            v-if="!isLoading && $store.state.compareDetails !== null"
            style="width: 100%"
            class="table table-bordered table-hover"
          >
            <thead>
              <tr>
                <th>Properties</th>
                <th>{{ word1 }}</th>
                <th>{{ word2 }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-show="
                  compareObj.key === 'name' || compareObj.key === 'description'
                "
                v-for="compareObj in $store.state.compareDetails"
                :key="compareObj"
                class="value-tr"
              >
                <td>{{ compareObj.label }}</td>
                <!-- <td>{{compareObj.value1}}</td>
                <td>{{compareObj.value2}}</td> -->
                <td
                  v-if="
                    typeof compareObj === 'object' &&
                    compareObj.value1.type === 'entity'
                  "
                >
                  <ol type="I">
                    <li v-for="item in compareObj.value1.value" :key="item.qid">
                      {{ item.value }}
                    </li>
                  </ol>
                </td>
                <td
                  v-if="
                    typeof compareObj === 'object' &&
                    compareObj.value1.type === 'text'
                  "
                >
                  {{ compareObj.value1.value }}
                </td>

                <td
                  v-if="
                    typeof compareObj === 'object' &&
                    compareObj.value2.type === 'entity'
                  "
                >
                  <ol type="I">
                    <li v-for="item in compareObj.value2.value" :key="item.qid">
                      {{ item.value }}
                    </li>
                  </ol>
                </td>
                <td
                  v-if="
                    typeof compareObj === 'object' &&
                    compareObj.value2.type === 'text'
                  "
                >
                  {{ compareObj.value2.value }}
                </td>
              </tr>

              <tr
                v-for="compareObj in $store.state.compareDetails"
                :key="compareObj"
                class="value-tr"
              >
                <td>{{ compareObj.label }}</td>
                <!-- <td>{{compareObj.value1}}</td>
                <td>{{compareObj.value2}}</td> -->
                <td
                  v-if="
                    typeof compareObj === 'object' &&
                    compareObj.value1.type === 'entity'
                  "
                >
                  <ol type="I">
                    <li v-for="item in compareObj.value1.value" :key="item.qid">
                      {{ item.value }}
                    </li>
                  </ol>
                </td>
                <td
                  v-if="
                    typeof compareObj === 'object' &&
                    compareObj.value1.type === 'text'
                  "
                >
                  {{ compareObj.value1.value }}
                </td>

                <td
                  v-if="
                    typeof compareObj === 'object' &&
                    compareObj.value2.type === 'entity'
                  "
                >
                  <ol type="I">
                    <li v-for="item in compareObj.value2.value" :key="item.qid">
                      {{ item.value }}
                    </li>
                  </ol>
                </td>
                <td
                  v-if="
                    typeof compareObj === 'object' &&
                    compareObj.value2.type === 'text'
                  "
                >
                  {{ compareObj.value2.value }}
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else-if="isLoading">
            <FingerprintSpinner
              :animation-duration="1000"
              :size="80"
              :color="'#0a58ca'"
              class="custom-spinner"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="$store.state.compareList.length !== 0" id="compare-list">
      <dark-slots>
        <div id="compare-list_list">
          <h3>List</h3>
          <!-- form with checkbox -->
          <form
            @submit.prevent="compareListFetch(checkedList)"
            class="custom-form-1 mb-3"
          >
            <div class="form-group">
              <div v-for="item in compareList" :key="item.id">
                <input
                  type="checkbox"
                  :id="item.word"
                  :value="item.word"
                  v-model="checkedList"
                />
                <label :for="item.word">{{ item.word }}</label>
              </div>
              <br />
            </div>
          </form>
        </div>
      </dark-slots>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FingerprintSpinner } from 'epic-spinners';
import DarkSlots from '@/slots/DarkSlots.vue';

export default {
  data() {
    return {
      checkedList: [],
      word1: '',
      word2: '',
      isLoading: false,
      compareList: [],
    };
  },
  components: {
    FingerprintSpinner,
    DarkSlots,
  },
  computed: {
    ...mapGetters({
      user: 'auth/authUser',
    }),
  },
  watch: {
    checkedList(payload) {
      if (payload.length === 2) {
        // eslint-disable-next-line
        this.word1 = payload[0];
        // eslint-disable-next-line
        this.word2 = payload[1];
        this.compareWords();
      }
    },
  },
  mounted() {
    this.user.then(() => {
      setTimeout(() => {
        // this.word1 = 'Money Heist';
        // this.word2 = 'Narcos';
        // this.compareWords();
        this.$store.dispatch('compareListAction').then(() => {
          this.compareList = this.$store.state.compareList;
        });
      }, 1000);
    });
  },
  methods: {
    compareListFetch(wordlist) {
      // eslint-disable-next-line
      this.word1 = wordlist[0];
      // eslint-disable-next-line
      this.word2 = wordlist[1];
      this.compareWords();
    },
    compareWords() {
      const { word1 } = this;
      const { word2 } = this;
      const wordDetail = {
        word1,
        word2,
      };
      this.isLoading = true;
      this.$store.dispatch('comapreDetailAction', wordDetail).then(() => {
        this.isLoading = false;
      });
    },
    compareMyWords(word1, word2) {
      this.word1 = word1;
      this.word2 = word2;
      this.compareWords();
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#experiment {
  position: relative;
  min-height: 50vh;
  h3 {
    color: #033d93;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
  }
  display: flex;
  padding: 1.25em;
  color: #ededed;
  border-radius: 0.625em;
  // tranparent blur effect
  width: 70%;
  margin: 3% auto;
  // #main-exp, #compare-list {
  //   position: relative;
  //   padding: 1.25eem;
  //   color: #fff;
  //   border-radius: 0.625en;
  //   backdrop-filter: blur(0.9375em);
  //   -webkit-backdrop-filter: blur(0.9375em);
  //   // background color
  //   background-color: rgba(0, 0, 0, 0.199);
  // }
  #main-exp {
    padding: 1.25em;
    background-color: #fff !important;
    // box-shadow: rgba(99, 99, 99, 0.2) 0em 0.125em 0.5em 0em;
    // width: 75%;
  }
  #compare-list {
    &_list {
      padding: 1.25em;
    }
    font-family: "Poppins", sans-serif;
    width: 25%;
    margin-left: 0.9375em;
    font-size: 0.9rem;
    div {
      margin-bottom: 0.25em;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: #0a58ca;
      }
    }
  }
  table {
    font-family: "Poppins", sans-serif;
    color: #000;
    thead {
      color: #0a58ca;
      &:hover {
        color: #0a58ca;
      }
    }
    .value-tr {
      width: 100%;
      &:hover {
        color: #000;
      }
    }
  }
  .custom-form {
    display: flex;
    .input-custom-field {
      width: 40%;
      input {
        backdrop-filter: blur(0.375em);
        -webkit-backdrop-filter: blur(0.375em);
        background-color: #fff;
        color: #000;
        font-family: "Poppins", sans-serif;
        box-shadow: #d6e0ef 0em 0em 0em 0.0625em inset;
      }
    }
    button {
      background-color: #0a58ca;
      color: #fff;
      font-family: "Poppins", sans-serif;
      width: 20%;
      border: none;
    }
  }
}
</style>
